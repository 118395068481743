import http from "@/http";

const Files = {
    upload(id, name, file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return http.post(`/files/${id}/upload`, formData, { headers });
    },
    uploadXml(id, name, file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return http.post(`/files/${id}/uploadXml`, formData, { headers });
    },
    getFiles(id) {
        return http.get(`/files/${id}`);
    },
    async getFile(id, name) {
        const resp = await http.get(`/files/${id}/${name}`, {responseType: 'blob'});
        const blob = new Blob([resp.data], { type: 'application/octet-stream' })
        const link = document.createElement('a')
        link.download = name;
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    },
    async downloadAll(id, files) {
        const resp = await http.get(`/files/${id}/all`, {responseType: 'blob', params: {files: files.join(';')}});
        console.log(resp.data);
        const blob = new Blob([resp.data], { type: 'application/zip' })
        const link = document.createElement('a')
        link.download = 'download.zip';
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    },
    deleteFile(id, name) {
        return http.delete(`/files/${id}/${name}`);
    },
    getPathToImg(id, name) {
        return `${process.env.VUE_APP_API_URL}/files/${id}/images/${name}`;
    }
};

export default Files;
