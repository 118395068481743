<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button v-has-permission="'REQUISITION_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('REQUISITION')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <b-button class="ml-1 btn btn-dark" v-b-toggle.filter-collapse>
        <b-icon-search class="mr-1"></b-icon-search>
        {{$t('FILTER_LABEL') | capitalize}}
      </b-button>

      <!-- Excel export -->
      <download-excel class="ml-1 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>

    <b-collapse id="filter-collapse" class="mt-2">
      <!-- List page filter -->
      <requisition-list-filter :only-active="onlyActive" :only-authorized="onlyAuthorized" class="d-inline-block w-75" @search="setFilter"
                               v-model="filter"></requisition-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </b-collapse>

    <b-modal id="digital-file-modal" :title="$t('FILE_MODAL_TITLE')" size="xl" hide-footer>
      <files-resume :id="this.id" :name="this.id"></files-resume>
    </b-modal>

    <!-- table -->
    <b-table
        id="requisition-table"
        ref="requisitionTable"
        @row-selected="handleSelection"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong
              class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('REQUISITIONS_LABEL')})  | capitalize }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <!-- concept -->
      <template #head(concept)="">
        <span>{{ labels.concept | capitalize }}</span>
      </template>

      <template #cell(concept)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.concept }}</span>
        </div>
      </template>
      <!-- concept -->

      <!-- observations -->
      <template #head(observations)="">
        <span>{{ labels.observations | capitalize }}</span>
      </template>

      <template #cell(observations)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.observations }}</span>
        </div>
      </template>
      <!-- observations -->
      <!-- paymentDate -->
      <template #head(paymentDate)="">
        <span>{{ labels.paymentDate | capitalize }}</span>
      </template>

      <template #cell(paymentDate)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.paymentDate | date }}</span>
        </div>
      </template>
      <!-- paymentDate -->

      <!-- type -->
      <template #head(total)="">
        <span>{{ labels.total | capitalize }}</span>
      </template>

      <template #cell(total)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.total | currency }}</span>
        </div>
      </template>
      <!-- type -->

      <!-- type -->
      <template #head(balance)="">
        <span>{{ labels.balance | capitalize }}</span>
      </template>

      <template #cell(balance)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.balance | currency }}</span>
        </div>
      </template>
      <!-- type -->

      <!-- type -->
      <template #head(paid)="">
        <span>{{ labels.paid | capitalize }}</span>
      </template>

      <template #cell(paid)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.paid | currency }}</span>
        </div>
      </template>
      <!-- type -->

      <!-- account -->
      <template #head(supplier)="">
        <span>{{ labels.supplier | capitalize }}</span>
      </template>

      <template #cell(supplier)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.supplier }}</span>
        </div>
      </template>
      <!-- account -->

      <!-- account -->
      <template #head(donor)="">
        <span>{{ labels.donor | capitalize }}</span>
      </template>

      <template #cell(donor)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.donor }}</span>
        </div>
      </template>
      <!-- account -->

      <!-- number -->
      <template #head(number)="">
        <span>{{ labels.number | capitalize }}</span>
      </template>

      <template #cell(number)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.number }}</span>
        </div>
      </template>
      <!-- number -->

      <template #head(authorized)="">
        <span>{{ labels.authorized  | capitalize }}</span>
      </template>

      <template #cell(authorized)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.authorized"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'REQUISITION_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item @click="download(data.item)">
              {{ $t('REQUISITION_PDF_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'REQUISITION_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'REQUISITION_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'REQUISITION_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>

            <b-dropdown-item v-has-permission="'REQUISITION_DE_AUTHORIZE_PERMISSION'"
                             @click="unAuthorize(data.item)" v-if="data.item.authorized">
              {{ $t('REQUISITION_DE_AUTHORIZE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'REQUISITION_AUTHORIZE_PERMISSION'"
                             @click="authorize(data.item)" v-else>
              {{ $t('REQUISITION_AUTHORIZE_LABEL')  | capitalize }}
            </b-dropdown-item>

            <b-dropdown-item v-has-permission="'REQUISITION_LIST_FILES_PERMISSION'"
                             @click="showFileModal(data.item.uploadDirectory)">
              {{ $t('LIST_FILES_LABEL') | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="requisition-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, Form, NumberFilter, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import requisitions from "@/requisition";
import eventBus from "@/events";
import RequisitionListFilter from "@/requisition/RequisitionListFilter";
import PageSize from "@/components/PageSize";
import Reports from "@/reports";
import Requisition from "@/requisition";
import FilesResume from "@/files/FilesResume";

export default {
  name: "RequisitionList",
  props: ['selectMode', 'currentlySelected', 'onlyActive', 'onlyAuthorized', 'readOnly'],
  components: {FilesResume, PageSize, RequisitionListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage, NumberFilter, DateFilter],
  mounted() {
    eventBus.$on('LIST_COUNT_REQUISITION_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    clear() {
      this.filter = {status: this.onlyActive ? true : undefined, authorized: this.onlyAuthorized ? true : undefined};
      eventBus.$emit('CLEAR_FILTER');
    },
    async download(requisition) {
      try {
        this.isBusy = true;
        const params = {id: requisition.id, fromDate: new Date(), toDate: new Date(), fromNumber: 0, toNumber: 0};
        await Reports.download('REQUISITION_PDF_FORMAT', params, `${requisition.number}.pdf`);
      } catch (e) {
        this.sendError('ERROR_PDF_MESSAGE', {}, e);
      } finally {
        this.isBusy = false;
      }
    },
    async authorize(requisition) {
      try {
        if (confirm(this.$t('REQUISITION_AUTHORIZE_WARNING', requisition))) {
          this.isBusy = true;
          await Requisition.authorize(requisition.id);
          requisition.authorized = true;
          this.sendMessage('REQUISITION_AUTHORIZED_OK', requisition);
        }
      } catch (e) {
        this.sendMessage('REQUISITION_AUTHORIZED_ERROR', requisition, e);
      } finally {
        this.isBusy = false;
      }
    },
    async unAuthorize(requisition) {
      try {
        if (confirm(this.$t('REQUISITION_DE_AUTHORIZE_WARNING', requisition))) {
          this.isBusy = true;
          await Requisition.unAuthorize(requisition.id);
          requisition.authorized = false;
          this.sendMessage('REQUISITION_DE_AUTHORIZED_OK', requisition);
        }
      } catch (e) {
        this.sendMessage('REQUISITION_DE_AUTHORIZED_ERROR', requisition, e);
      } finally {
        this.isBusy = false;
      }
    }
  },
  data() {
    return {
      filter: {status: this.onlyActive ? true : undefined, authorized: this.onlyAuthorized ? true : undefined},
      labels: {
        concept: this.$t('REQUISITION_CONCEPT_LABEL'),
        observations: this.$t('REQUISITION_OBSERVATIONS_LABEL'),
        paymentDate: this.$t('REQUISITION_PAYMENT_DATE_LABEL'),
        total: this.$t('REQUISITION_TOTAL_LABEL'),
        balance: this.$t('REQUISITION_BALANCE_LABEL'),
        paid: this.$t('REQUISITION_PAID_LABEL'),
        donor: this.$t('REQUISITION_DONOR_LABEL'),
        supplier: this.$t('REQUISITION_SUPPLIER_LABEL'),
        number: this.$t('REQUISITION_NUMBER_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        authorized: this.$t('REQUISITION_AUTHORIZED_LABEL'),
        module: this.$t('REQUISITIONS_LABEL'),
        excel: this.$t('REQUISITIONS_LABEL') + '.xls',
      },
      registerPage: 'REQUISITION_REGISTER_TITLE',
      editPage: 'REQUISITION_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return requisitions;
    },
    fields() {
      const fields = [
        {key: 'multiselect', tdClass: 'selectTd'},
        {key: 'number', sortable: true, tdClass: 'customTd'},
        {key: 'paymentDate', sortable: true, tdClass: 'customTd'},
        {
          key: 'total',
          sortable: true,
          tdClass: 'customTd'
        },
        {
          key: 'balance',
          sortable: true,
          tdClass: 'customTd'
        },
        {
          key: 'paid',
          sortable: true,
          tdClass: 'customTd'
        },
        {key: 'donor', sortable: true, tdClass: 'customTd'},
        {key: 'supplier', sortable: true, tdClass: 'customTd'},
      ];

      if (!this.onlyActive) {
        fields.push({key: 'enabled', sortable: true, tdClass: 'enabledTd'})
      }
      if(!this.onlyAuthorized) {
        fields.push({key: 'authorized', sortable: true, tdClass: 'enabledTd'})
      }
      if (!this.readOnly) {
        fields.push({key: 'options', sortable: false, tdClass: 'optionsTd'});
      }


      return fields;
    },
    tableRef() {
      return this.$refs.requisitionTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.concept] = 'concept';
      fields[this.labels.observations] = 'observations';
      fields[this.labels.paymentDate] = 'paymentDate';
      fields[this.labels.total] = 'total';
      fields[this.labels.balance] = 'balance';
      fields[this.labels.paid] = 'paid';
      fields[this.labels.donor] = 'donor';
      fields[this.labels.supplier] = 'supplier';
      fields[this.labels.number] = 'number';
      return fields;
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
