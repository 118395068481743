<template>
  <div>
    <b-form class="mb-2" inline>
      <div class="w-100 mb-1">
        <b-form-input @blur="search"
                      v-model="filter.number"
                      class="w-100"
                      :placeholder="$t('FILTER_SEARCH_BY_NUMBER_LABEL', {catalog: $t('REQUISITION')}) | capitalize"
                      id="search-input"></b-form-input>
      </div>
      <div class="w-50 pr-1 mb-1">
        <b-form-datepicker
            @input="search"
            v-model="filter.from"
            right
            locale="es-MX"
            button-variant="dark"
            aria-controls="example-input"
            :label-no-date-selected="$t('FILTER_FROM_LABEL') | capitalize"></b-form-datepicker>
      </div>

      <div class="w-50 mb-1">
        <b-form-datepicker
            @input="search"
            v-model="filter.to"
            right
            locale="es-MX"
            button-variant="dark"
            aria-controls="example-input"
            :label-no-date-selected="$t('FILTER_TO_LABEL') | capitalize"></b-form-datepicker>
      </div>

      <div class="w-100 mb-1">
        <project-single-selector @input="search" value-type="id" undefined-means="PROJECT_FILTER_ALL_LABEL" v-model="filter.projectId"></project-single-selector>
      </div>

      <b-form-select v-if="!onlyActive" @change="search" v-model="filter.status" class="w-100 mb-1" id="status-select">
        <b-form-select-option :value="undefined">{{
            $t('FILTER_ALL_LABEL', {
              catalog: $t('REQUISITIONS'),
              gender: 'a'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="true">{{
            $t('FILTER_ACTIVE_LABEL', {
              catalog: $t('REQUISITION'),
              gender: 'a'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="false">{{
            $t('FILTER_INACTIVE_LABEL', {
              catalog: $t('REQUISITION'),
              gender: 'a'
            })  | capitalize
          }}
        </b-form-select-option>
      </b-form-select>

      <b-form-select v-if="!onlyAuthorized" @change="search" v-model="filter.authorized" class="w-100" id="status-select">
        <b-form-select-option :value="undefined">{{
            $t('FILTER_ALL_LABEL', {
              catalog: $t('REQUISITIONS'),
              gender: 'a'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="true">{{
            $t('FILTER_AUTHORIZED_LABEL')  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="false">{{
            $t('FILTER_UNAUTHORIZED_LABEL')  | capitalize
          }}
        </b-form-select-option>
      </b-form-select>
    </b-form>
  </div>
</template>

<script>
import {VModel, CapitalFilter, Filter} from "@/mixins";
import eventBus from "@/events";
import ProjectSingleSelector from "@/project/ProjectSingleSelector";

export default {
  name: "RequisitionListFilter",
  components: {ProjectSingleSelector},
  props: ['onlyActive', 'onlyAuthorized'],
  mixins: [VModel, CapitalFilter, Filter],
  data() {
    return {
      filter: {}
    }
  },
  mounted() {
    eventBus.$on('CLEAR_FILTER', () => {
      this.filter = {}
    })
  },
  methods: {
    search() {
      if (this.onlyActive) {
        this.filter.status = true;
      }
      if(this.onlyAuthorized) {
        this.filter.authorized = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>