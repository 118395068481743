import http from "@/http";
import eventBus from "@/events";
import Token from "@/token";

const Requisition = {
    findAll: async function (ctx) {
        if (ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'username') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const endpoint = Token.hasPermission('REQUISITION_LIST_ALL_PERMISSION') ? '/requisitions/search/all' : '/users/search/userRequisitions';
            const resp = await http(endpoint, {params});
            eventBus.$emit('LIST_COUNT_REQUISITION_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.requisitions;
        } else {
            const resp = await http("/requisitions/search/all");
            eventBus.$emit('LIST_COUNT_REQUISITION_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.requisitions;
        }
    },
    create: function (data) {
        return http.post('/requisitions', data);
    },
    update: function (id, data) {
        return http.patch('/requisitions/' + id, data);
    },
    findById(id) {
        return http.get('/requisitions/' + id);
    },
    enable(id) {
        return http.patch('/requisitions/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/requisitions/' + id + '/disable')
    },
    authorize(id) {
        return http.patch('/requisitions/' + id + '/authorize')
    },
    unAuthorize(id) {
        return http.patch('/requisitions/' + id + '/unAuthorize')
    },
    deleteById(id) {
        return http.delete('/requisitions/' + id)
    },
    invoices: async function (requisitionId) {
        const resp = await http.get('/invoices/search/byRequisitionId', {params: {requisitionId, perPage: 1000, sortBy: 'number'}});
        console.log(resp);
        return resp.data._embedded.dummyInvoices;
    },
    invoiceExists: async function(uuid) {
        const resp = await http.get('invoices/exists/' + uuid);
        return resp.data;
    }
};

export default Requisition;
